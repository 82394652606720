import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import translate from "../i18n/translate"
import Languages from "./reusable-components/languages"

function Navbar({ activeLangue, handleLangue }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3 buttons d-none d-md-block">
          <Languages handleLangue={handleLangue} activeLangue={activeLangue} />
        </div>
        <nav
          className="Navigation col-12 col-md-9 d-flex justify-content-end"
          id="navbar"
        >
          <button onClick={() => scrollTo("#about")}>
            {translate("nav-about")}
          </button>
          <button onClick={() => scrollTo("#exp")}>
            {translate("nav-exp")}
          </button>
          <button onClick={() => scrollTo("#projects")}>
            {translate("nav-projects")}
          </button>
        </nav>
      </div>
    </div>
  )
}

export default Navbar
