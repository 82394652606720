import { LOCALES } from "../locales"

export default {
  [LOCALES.ENGLISH]: {
    "nav-about": "About",
    "nav-exp": "Edu && EX",
    "nav-projects": "Projects",
    download: "download CV",
    "button-visitSite": "Visit the site",
    "greeting-1": "Hello!",
    "greeting-2": "I am VALERIYA,",
    "greeting-3": "junior Web Developer",
    message:
      "I have education and experience in the tourism field. But after moving to Belgium in the end of 2017, I decided to change my professional path verse Web Development. I am interested in everything that it contains. In March 2020, I finished a Programmation course at BeCode. Now I'm currently doing an internship at BloomingRiders. I love JavaScript, and ReactJs is the main technology I work with.",
    "heading-education": "Education && Experience",
    "heading-projects": "Projects",
    country: "Belgium",
    "country-city": "Charleroi, Belgium",
    "date-becode": "September, 2019 - March, 2020",
    "description-becode":
      "7 months training in front-end development (HTML, CSS, Javascript), back-end development (PHP, SQL, Adminer), systems administration (Linux), project management. Self-learning, autonomy, team-work.",
    "description-technofuture": "Introduction to HTML, JavaScript, PHP.",
    "date-blooming": "April, 2020 - present time",
    "description-blooming":
      "Front-end development of the lms platform (reactJS, gatsbyJS, nodeJS, Redis, SASS )",
    a6k:
      "It was a group project during my studies. The site has already been working for some time, but we solved a lot of small issues. I worked with JS (created animations, rewrote functions, analyzed the code to delete unused functions), but as the site was created with WordPress, I did code in PHP and used some functionality of WordPress, MySQL, Adminer.",
    ancubator:
      "It is my first personal project - a very simple site. Technologies: HTML, Bootstrap, Figma, Mailchimp",
    jobswap:
      "This project consists of two parts: admin panel and site. Technologies: reactjs, gatsbyjs, CSS, SASS.",
    blooming:
      "This project has two parts: admin-panel and site. I realized the admin panel(all front-end functionality) during my internship at Blooming Riders. We created LMS platform from scratch. Technologies: reactjs, gatsbyjs, redis, CSS, SASS, multi-services. I am working on the site right now :) Technologies: reactjs, nextjs, react-redux, redis, CSS, SASS.",
    adminBlooming: "bla bla bla",
  },
}
