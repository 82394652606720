import { LOCALES } from "../locales"

export default {
  [LOCALES.FRENCH]: {
    "nav-about": "About",
    "nav-exp": "Edu && EX",
    "nav-projects": "Projects",
    download: "télécharger mon CV",
    "button-visitSite": "Visitez le site",
    "greeting-1": "Salut!",
    "greeting-2": "Je suis Valeriya,",
    "greeting-3": "développeur web junior",
    message:
      "J'ai l'éducation et l'expérience dans le domaine du tourisme. Mais après avoir déménagé en Belgique fin 2017, j'ai décidé de changer mon parcours professionnel vers le développement Web. Je m'intéresse à tout ce qu'il contient. En mars 2020, j'ai terminé un cours de programmation chez BeCode. Maintenant, je fais actuellement un stage chez BloomingRiders. J'adore JavaScript et ReactJs est la principale technologie avec laquelle je travaille.",
    "heading-education": "Education && Experience",
    "heading-projects": "Projects",
    country: "Belgique",
    "country-city": "Charleroi, Belgique",
    "date-becode": "Septembre, 2019 - mars, 2020",
    "description-becode":
      "Formation de 7 mois en développement front-end (HTML, CSS, Javascript), développement back-end (PHP, SQL, Adminer), administration de systèmes (Linux), gestion de projet. Auto-apprentissage, autonomie, travail d'équipe.",
    "description-technofuture": "Introduction aux HTML, JavaScript, PHP",
    "date-blooming": "Avril, 2020 - jusqu'a présent",
    "description-blooming":
      "Développement front-end de la plateforme lms (reactJS, gatsbyJS, nodeJS, Redis, SASS)",
    a6k:
      "C'était un projet de groupe pendant mes études. Le site fonctionne déjà depuis un certain temps, mais nous avons résolus beaucoup de petits problèmes. J'ai travaillé avec JS (créer des animations, réécrire des fonctions, analyser le code pour supprimer les fonctions inutilisées), mais comme le site a été créé avec WordPress, j'ai fait du code en PHP et utilisé certaines fonctionnalités de WordPress, MySQL, Adminer.",
    ancubator:
      "C'est mon premier projet personnel - un site très simple. Technologies: HTML, Bootstrap, Figma, Mailchimp",
    "admin-blooming":
      "J'ai réalisé ce projet lors de mon stage chez Blooming Riders. Nous avons créé la plate-forme LMS à partir de zéro. J'ai créé toutes les fonctionnalités frontales pour le panneau d'administration. Technologies: reactjs, gatsbyjs, redis, CSS, SASS, multi-services.",
    blooming:
      "Je travaille sur ce projet en ce moment :) Technologies: reactjs, gatsbyjs, react-redux, redis, CSS, SASS.",
    adminBlooming: "bla bla bla",
  },
}
