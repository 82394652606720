import React from "react"
import { LOCALES } from "../../i18n"

function Languages({ handleLangue, activeLangue }) {
  return (
    <>
      <button
        className={activeLangue === "en" ? "activeLangue" : ""}
        onClick={() => handleLangue(LOCALES.ENGLISH)}
      >
        EN
      </button>
      <button
        className={activeLangue === "fr" ? "activeLangue" : ""}
        onClick={() => handleLangue(LOCALES.FRENCH)}
      >
        FR
      </button>
      <button
        className={activeLangue === "ru" ? "activeLangue" : ""}
        onClick={() => handleLangue(LOCALES.RUSSIAN)}
      >
        RU
      </button>
    </>
  )
}

export default Languages
