import React, { useState } from "react"
import translate from "../i18n/translate"
import Logo from "./logo"
import CvEn from "../images/cv-Valeriya-Kozlova-en.pdf"
import CvFr from "../images/cv-Valeriya-Kozlova-fr.pdf"
import Arrow from "./reusable-components/arrow"
import { motion } from "framer-motion"
import Check from "./reusable-components/check"
import Languages from "./reusable-components/languages"

function Footer({ handleLangue, activeLangue }) {
  const [isHovered, setHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const download = {
    visible: {
      display: "inline-block",
    },
    hidden: {
      display: "none",
      transform: "translateY(100px)",
    },
  }
  const done = {
    hidden: {
      display: "none",
      transform: "translateY(100px)",
    },
    visible: {
      display: "inline-block",
      transform: "translateY(0px)",
    },
  }
  const bg = {
    hidden: {
      background: "black",
    },
    visible: {
      background: "#7b40f2",
    },
  }
  return (
    <footer>
      <div className="container mainContainer">
        <div className="row">
          <div className="col-3 col-md-1 offset-md-1 logo">
            <Logo />
          </div>
          <div className="contactTwo col-9 col-md-6">
            <a className="email" href="mailto: kozlova.valeriya.v@gmail.com">
              kozlova.valeriya.v@gmail.com
            </a>
            <div className="contact">
              <a
                href="https://www.facebook.com/valeriya.kozlova.73"
                target="_blank"
                rel="noopener noreferrer"
              >
                f
              </a>
              <a
                href="https://www.linkedin.com/in/valeriya-kozlova-v/"
                target="_blank"
                rel="noopener noreferrer"
              >
                in
              </a>
              <a
                href="https://github.com/ValeriyaKozlova"
                target="_blank"
                rel="noopener noreferrer"
              >
                git
              </a>
            </div>
          </div>
          <div className="col-12 col-md-4 download">
            <a
              href={activeLangue === "fr" ? CvFr : CvEn}
              download
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => setIsClicked(true)}
            >
              <span className="text">
                <motion.span
                  variants={download}
                  initial="visible"
                  animate={isClicked && "hidden"}
                  transition={{
                    default: { delay: 1, duration: 0.1, ease: "easeInOut" },
                  }}
                >
                  {isClicked ? "downloading..." : translate("download")}
                </motion.span>
                <motion.span
                  variants={done}
                  initial="hidden"
                  animate={isClicked && "visible"}
                  transition={{
                    default: { delay: 1, duration: 0.2, ease: "easeInOut" },
                  }}
                >
                  Done
                </motion.span>
              </span>
              <motion.span
                className="arrow"
                variants={bg}
                initial="hidden"
                animate={isClicked && "visible"}
              >
                {isClicked ? (
                  <Check checked={isClicked} />
                ) : (
                  <Arrow isHovered={isHovered} />
                )}
              </motion.span>
            </a>
          </div>
          <div className="col-12 d-md-none text-center">
            <Languages handleLangue={handleLangue} />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
