import { LOCALES } from "../locales"

export default {
  [LOCALES.RUSSIAN]: {
    "nav-about": "Обо мне",
    "nav-exp": "Мой путь",
    "nav-projects": "Проекты",
    download: "скачать мое CV",
    "button-visitSite": "Посетить сайт",
    "greeting-1": "Привет!",
    "greeting-2": "Меня зовут Валерия,",
    "greeting-3": "младший веб разработчик",
    message:
      "Я получила образование в сфере туризма, и работала по профессии. Но после переезда в Бельгию в конце 2017 года, я решила изменить свой профессиональный путь в сторону веб-разработки. Меня интересует все, что в ней содержится. В марте 2020 года я закончила курс программирования BeCode. Сейчас я прохожу стажировку в BloomingRiders. Я люблю JavaScript, а ReactJs - это основная технология, с которой я работаю.",
    "heading-education": "Образование && опыт работы",
    "heading-projects": "Проекты",
    country: "Бельгия",
    "country-city": "Шарлеруа, Бельгия",
    "date-becode": "Сентябрь, 2019 - март, 2020",
    "description-becode":
      "7-ми месячные интенсивные курсы по фронтенд-разработке(HTML, CSS, Javascript), бэкенд-разработке(PHP, SQL, Adminer), системам администрирования(Linux), разработке проектов. Развитие умения автономной работы и обучения, работы в команде.",
    "description-technofuture": "Введение в HTML, JavaScript, PHP.",
    "date-blooming": "Апрель, 2020 - настоящее время",
    "description-blooming":
      "Разработка фронтэнда для обучающей платформы (ReactJS, gatsbyJS, nodeJS, Redis, SASS)",
    a6k:
      "Это был групповой проект во время обучения. Сайт уже работал, но мы решали множество мелких проблем. Я работала с JS (создавала анимацию, переписывала функции, анализировала код для удаления неиспользуемых функций), но, поскольку сайт создавался с помощью WordPress, я делала код на PHP и использовала некоторые функции WordPress, MySQL, Adminer.",
    ancubator:
      "Это мой первый личный проект - очень простой сайт. Технологии: HTML, Bootstrap, Figma, Mailchimp",
    "admin-blooming":
      "Я реализовал этот проект во время моей стажировки в Blooming Riders. Мы создали платформу LMS с нуля. Я сделал все функции интерфейса для панели администратора. Технологии: реагирование, гэтсби, редис, CSS, SASS, мультисервис.",
    blooming:
      "Я работаю над этим проектом прямо сейчас :) Технологии: ReactionJS, GatsbyJS, React-Redux, Redis, CSS, SASS.",
    adminBlooming: "bla bla bla",
  },
}
