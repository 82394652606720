import PropTypes from "prop-types"
import React from "react"
import Navbar from "../Navbar"

const Header = ({ handleLangue }) => (
  <header className="container-fluid">
    <Navbar handleLangue={handleLangue} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
