import React from "react"

function Check() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: " new 0 0 512 512", height: "35px" }}
      xmlSpace="preserve"
    >
      <path className="check" d="M488,88L175.7,400L23.9,248.3" />
    </svg>
  )
}

export default Check
